export const CARRIER_ID = 'ebded4c8-7d7a-4534-89ac-c3cbf3d2185a'
export const SSO_URL = 'https://sso.rotate.group/'
export const CHECK_WX_API_KEY = '5f834ed10513dd66e5d3850fb5'
export const CARRIER_CALLSIGN = 'DM'
export const MAPBOX_URI =
  'https://api.mapbox.com/styles/v1/yancarlos4500/cljzzabbr00rt01nshdd09iom/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoieWFuY2FybG9zNDUwMCIsImEiOiJja2ZrbnQzdmExMDhnMzJwbTdlejNhdnJuIn0.aoHpGyZLaQRcp8SPYowuOQ'
export let API_URL =
  process.env.NODE_ENV === 'development'
    ? //'http://localhost:8787/'
    'https://api-v2.rotate.group/'
    : 'https://api-v2.rotate.group/'

export const socialLinks = [

]

export const skipAuthPage = false
